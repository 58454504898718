/*
-----------------------------------------
Open Affix
-----------------------------------------
*/
$('[data-trigger="affix"]').on('click', function () {
  var affix = $('.affix');

  if (affix.hasClass('is-open')) {
    affix.removeClass('is-open');
  } else {
    affix.addClass('is-open');
  }
});