/*
-----------------------------------------
Scss
-----------------------------------------
*/
import '~/components/main.scss';
/*
-----------------------------------------
Transpiling
-----------------------------------------
*/

import "core-js/stable";
import "regenerator-runtime/runtime";
/*
-----------------------------------------
Global Vendors
-----------------------------------------
*/
// Popper

import 'popper.js'; // Bootstrap

import 'bootstrap';
/*
-----------------------------------------
Glob Import components
-----------------------------------------
*/

import './03-organisms/affix/affix.js'; import './03-organisms/fullpage/fullpage.js'; import './03-organisms/header/header.js'; import './main.js';