/*
-----------------------------------------
Navigation Trigger
-----------------------------------------
*/
$('[data-trigger="navigation"]').on('click', function () {
  var trigger = $(this),
      navigation = $('[data-navigation]');

  if (trigger.hasClass('is-active')) {
    trigger.add(navigation).removeClass('is-active');
  } else {
    trigger.add(navigation).addClass('is-active');
  }
});