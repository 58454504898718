import "core-js/modules/es.string.anchor";
import _setTimeout from "@babel/runtime-corejs3/core-js-stable/set-timeout";

/*
-----------------------------------------
Fullpage -- https://github.com/alvarotrigo/fullPage.js/#fullpagejs
-----------------------------------------
*/
import fullpage from 'fullpage.js';
/*
-----------------------------------------
Init
-----------------------------------------
*/

var lastSection = false;
var myFullpage = new fullpage('#fullpage', {
  licenseKey: 'AD467576-23A64240-ABC35E22-76DEAAEF',
  anchors: ['landing', 'whistleblower', 'infos', 'contact', 'procedures'],
  menu: '[data-menu]',
  responsiveWidth: 991,
  normalScrollElements: '[data-navigation]',
  animateAnchor: false,
  onLeave: function onLeave(origin, distination) {
    var trigger = $('[data-trigger="navigation"]'),
        navigation = $('[data-navigation]'),
        affix = $('.affix'),
        fullpageMenu = $('[data-menu="fullpage"]'),
        fullpageIndicator = $('[data-indicator="fullpage"]'); // Update lastSection

    lastSection = distination.anchor == "procedures" ? true : false; // Close Navigation on trigger menu

    if (trigger.hasClass('is-active')) {
      trigger.add(navigation).removeClass('is-active');
    } // Hide affix & fullpage menu when procedures section is active


    if (lastSection) {
      affix.add(fullpageMenu).addClass('is-hidden');
      fullpageIndicator.addClass('is-last');
    } else {
      affix.add(fullpageMenu).removeClass('is-hidden');
      fullpageIndicator.removeClass('is-last');
    }
  },
  afterRender: function afterRender(origin) {
    var affix = $('.affix'),
        fullpageMenu = $('[data-menu="fullpage"]'),
        fullpageIndicator = $('[data-indicator="fullpage"]'); // Update lastSection

    lastSection = origin.anchor == "procedures" ? true : false; // Hide affix & fullpage menu when procedures section is active

    if (lastSection) {
      affix.add(fullpageMenu).addClass('is-hidden');
      fullpageIndicator.addClass('is-last');
    } else {
      affix.add(fullpageMenu).removeClass('is-hidden');
      fullpageIndicator.removeClass('is-last');
    }
  }
});
/*
-----------------------------------------
Anchors
-----------------------------------------
*/

$('[data-menuanchor] a').on('click', function () {
  // Close Mobile Navigation
  var trigger = $('[data-trigger="navigation"]'),
      navigation = $('[data-navigation]');
  trigger.add(navigation).removeClass('is-active'); // Remove Scrolling Animation

  myFullpage.setScrollingSpeed(0); // Reset Scrolling animation

  _setTimeout(function () {
    myFullpage.setScrollingSpeed(700);
  }, 100);
});
console.log($('#procedure-mail').offset().top); // Custom mobile anchors for procedures section

$('[data-mobileanchor] a[href*="#"]:not([href="#"])').click(function () {
  var href = this.hash,
      procedureMail = href === '#procedure-mail' ? true : false,
      target = procedureMail ? $('#procedure-mail') : $('[data-anchor="' + href.substring(1, href.length) + '"]'),
      navigation = $('.navigation'),
      targetOffset = $(target).offset().top,
      navOffset = 40; // Reset mobile anchors

  $('[data-mobileanchor]').removeClass('is-active');
  $(this).parent().addClass('is-active'); // Close navigation

  if (navigation.hasClass('is-active')) {
    navigation.add('[data-trigger="navigation"]').removeClass('is-active');
  } // Animate scroll


  $('html,body').animate({
    scrollTop: targetOffset - navOffset
  }, procedureMail ? 10 : 0);
});
/*
-----------------------------------------
Scroll Indicator
-----------------------------------------
*/

$('[data-indicator="fullpage"]').on('click', function () {
  // Remove Scrolling Animation
  myFullpage.setScrollingSpeed(0); // If last section scroll top otherwise scroll bottom

  if (lastSection) {
    myFullpage.moveTo(1);
  } else {
    myFullpage.moveSectionDown();
  } // Reset Scrolling animation


  _setTimeout(function () {
    myFullpage.setScrollingSpeed(700);
  }, 100);
});